<template>
    <vs-row direction="column">
        <vs-dialog v-model="cropperDialog" prevent-close>
          <cropper v-if="cropperDialog"
          class="cropper"
          ref="cropper"
          :src="cropperProps.src"
          :stencil-props="{
            aspectRatio: cropperProps.aspectRatio,
          }"
          @change="cropperProps.change"
          default-boundaries="fill"
        ></cropper>

        <!-- button to rotate left, right flip horizzontal and vertical, also to fill-->
         <vs-row style="position: absolute; left: 20px; top: 20px;" direction="column">
          <vs-button  icon @click="$refs.cropper.rotate(-90)"> <i class="bx bx-rotate-left bx-sm"></i> </vs-button>
          <vs-button  icon @click="$refs.cropper.rotate(90)"> <i class="bx bx-rotate-right bx-sm"></i> </vs-button>




         </vs-row>

        <template #footer>
          <vs-button @click="cropperDialog = false" size="large" block>Conferma e chiudi</vs-button>
        </template>
        </vs-dialog>

        <input type="file" ref="fileInput" @change="fileInputChange" style="display: none;" />
        <vs-row justify="space-between" align="center" v-if="!builderData.userMode">
          <p>Debug:
                {{ layout.width }}x{{ layout.height }} mm -
                {{ layout.marginTop }}x{{ layout.marginRight }}x{{ layout.marginBottom }}x{{ layout.marginLeft }} mm -
                {{ configKonva.width.toFixed(2) }}x{{ configKonva.height.toFixed(2) }} px (scaled by {{ scaleFactor.toFixed(2) }})
          </p>
          <vs-row style="width: unset!important;">
            <vs-button border dark icon @click="userMode = !userMode"> <i class="bx bx-pen bx-sm"></i> {{ userMode ? 'Passa a Amministratore' : 'Passa a Utente' }}</vs-button>
            <!-- add paragraph, add image buttons with icon as well -->
            <vs-button icon @click="addParagraph"> <i class="bx bx-text bx-sm"></i> Paragrafo</vs-button>
            <vs-button icon @click="uploadFile(null, 'builderImage', 'image/*')"> <i class="bx bx-image bx-sm"></i> Immagine</vs-button> 
            <vs-button icon @click="addQR()"> <i class="bx bx-barcode bx-sm"></i> QR Code</vs-button>
            <vs-button icon @click="uploadFile(null, 'builderBackgroundImage', 'image/*')"> <i class="bx bx-image-add bx-sm"></i> Sfondo</vs-button>
        
            <vs-button @click="editLayout" icon> <i class="bx bx-save bx-sm"></i> Salva</vs-button>
            </vs-row>
        </vs-row>
        <vs-row v-if="configKonva.width > 0" ref="stageContainer" :justify="userMode ? 'center' : undefined">
            <BuilderToolbar id="builder-toolbar" v-if="toolbarOpts" :options="toolbarOpts" :user-mode="userMode" @delete="deleteElement" @upload="uploadFile(toolbarOpts.id, 'builderImage', 'image/*', 'cropper')" @crop="initCropper(toolbarOpts.id)" 
              @fillChanged="fillChanged" @sizeChanged="sizeChanged" @fontStyleChanged="fontStyleChanged" @lockChanged="lockChanged"/>
              
            <v-stage :config="configKonva" @mousedown="handleStageMouseDown" @touchstart="handleStageMouseDown" ref="stage">
               <v-layer>
                <v-rect :config="backgroundRect"></v-rect>
               </v-layer>

                

                <v-layer>
                    <v-image v-for="image in elements.filter(e => e.type === 'background')" :config="image" :key="image.name" @transformend="handleTransformEnd" />
                    
                    <!--image-->
                    <v-image v-for="image in elements.filter(e => e.type === 'image' || e.type == 'qrcode')" :config="image" :key="image.name" @transformend="handleTransformEnd"  @transform="handleTransform" @dragmove="handleDrag" />
                    <!--text-->
                    <v-text v-for="paragraph in elements.filter(e => e.type === 'text')" :config="paragraph" :key="paragraph.name" @transformend="handleTransformEnd" @dblclick="doubleClick" @transform="handleTransform" @dragmove="handleDrag" />

                    <v-transformer ref="transformer" />


                </v-layer>

                <v-layer>
                    <!-- rectangle that rappresents the margins-->
                    <!-- create it using lines-->
                    <v-line
                        v-if="builderData.layout.type != 'Spine'"
                        :config="{
                            points: [
                                // top left corner
                                this.layout.marginLeft * MM_TO_PX,
                                this.layout.marginTop * MM_TO_PX,

                                // top right corner
                                (this.layout.width - this.layout.marginRight) * MM_TO_PX,
                                this.layout.marginTop * MM_TO_PX,

                                // bottom right corner
                                (this.layout.width - this.layout.marginRight) * MM_TO_PX,
                                (this.layout.height - this.layout.marginBottom) * MM_TO_PX,

                                // bottom left corner
                                this.layout.marginLeft * MM_TO_PX,
                                (this.layout.height - this.layout.marginBottom) * MM_TO_PX,

                                // top left corner
                                this.layout.marginLeft * MM_TO_PX,
                                this.layout.marginTop * MM_TO_PX,
                            ],
                            stroke: 'black',
                            strokeWidth: 0.2 * MM_TO_PX, // 1px
                            // dashed
                            dash: [DASH_LENGTH * MM_TO_PX, DASH_GAP * MM_TO_PX],
                        }"
                    ></v-line>

                    <!-- draw the crocino di taglio on the corneres, it looks like a cross -->

                    <!-- top left corner -->
                    <v-line
                        v-if="builderData.layout.type != 'Spine'"
                        :config="{
                            points: [
                                this.layout.marginLeft * MM_TO_PX,
                                this.layout.marginTop * MM_TO_PX - (5 * MM_TO_PX),

                                this.layout.marginLeft * MM_TO_PX,
                                this.layout.marginTop * MM_TO_PX,

                                // go to left
                                this.layout.marginLeft * MM_TO_PX - (5 * MM_TO_PX),
                                this.layout.marginTop * MM_TO_PX,

                                
                            ],
                            stroke: 'red',
                            strokeWidth: 0.3 * MM_TO_PX, // 1px
                        }"
                    ></v-line>

                    <!-- top right corner -->
                    <v-line
                        v-if="builderData.layout.type != 'Spine'"

                        :config="{
                            points: [
                                (this.layout.width - this.layout.marginRight) * MM_TO_PX,
                                this.layout.marginTop * MM_TO_PX - (5 * MM_TO_PX),

                                (this.layout.width - this.layout.marginRight) * MM_TO_PX,
                                this.layout.marginTop * MM_TO_PX,

                                // go to right
                                (this.layout.width - this.layout.marginRight) * MM_TO_PX + (5 * MM_TO_PX),
                                this.layout.marginTop * MM_TO_PX,
                            ],
                            stroke: 'red',
                            strokeWidth: 0.3 * MM_TO_PX, // 1px
                        }"
                    ></v-line>

                    <!-- bottom right corner -->
                    <v-line
                    v-if="builderData.layout.type != 'Spine'"

                        :config="{
                            points: [
                                (this.layout.width - this.layout.marginRight) * MM_TO_PX + (5 * MM_TO_PX),
                                (this.layout.height - this.layout.marginBottom) * MM_TO_PX,

                                (this.layout.width - this.layout.marginRight) * MM_TO_PX,
                                (this.layout.height - this.layout.marginBottom) * MM_TO_PX,

                                // go to right
                                (this.layout.width - this.layout.marginRight) * MM_TO_PX,
                                (this.layout.height - this.layout.marginBottom) * MM_TO_PX + (5 * MM_TO_PX),
                            ],
                            stroke: 'red',
                            strokeWidth: 0.3 * MM_TO_PX, // 1px
                        }"
                    ></v-line>

                    <!-- bottom left corner -->
                    <v-line
                        v-if="builderData.layout.type != 'Spine'"
                        :config="{
                            points: [
                                this.layout.marginLeft * MM_TO_PX - (5 * MM_TO_PX),
                                (this.layout.height - this.layout.marginBottom) * MM_TO_PX,

                                this.layout.marginLeft * MM_TO_PX,
                                (this.layout.height - this.layout.marginBottom) * MM_TO_PX,

                                // go to left
                                this.layout.marginLeft * MM_TO_PX,
                                (this.layout.height - this.layout.marginBottom) * MM_TO_PX + (5 * MM_TO_PX),
                            ],
                            stroke: 'red',
                            strokeWidth: 0.3 * MM_TO_PX, // 1px
                        }"
                    ></v-line>
                    
                </v-layer>
            </v-stage>
        </vs-row>
    </vs-row>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import { v4 as uuidv4 } from 'uuid';
import {
    apiCall,
} from '@/client';
import {
    s3Client
} from '@/s3Client';
import BuilderToolbar from '@/components/BuilderToolbar.vue';
const QRCode = require('qrcode')

const DPI = 400;

// calculate how many pixels needed per mm
const MM_TO_PX = DPI / 25.4;
const PX_TO_MM = 1 / MM_TO_PX;

const DASH_LENGTH = 5;
const DASH_GAP = 5;

export default {
    props: {
      builderData: {
        type: Object,
        default: () => {},
        required: true,
      }
    },
    data() {
        return {
            layout: {
                name: 'A4',
                width: 210, // in mm
                height: 297, // in mm
                marginTop: 5,
                marginRight: 5,
                marginBottom: 5,
                marginLeft: 5,
            },
            configKonva: {
                width: 0,
                height: 0,
                scaleX: 1,
                scaleY: 1,
            },
            
            scaleFactor: 1,

            elements: [],

            MM_TO_PX,
            PX_TO_MM,
            DASH_LENGTH,
            DASH_GAP,

            selectedShapeName: '',

            toolbarOpts: null,

            userMode: false,

            cropperProps: null,
            cropperDialog: false,
            cropperConfirm: null,
        }
    },

    components: {
        BuilderToolbar,
        Cropper
    },
    computed: {
        backgroundRect() {
            return {
                x: 0,
                y: 0,
                width: this.layout.width * MM_TO_PX,
                height: this.layout.height * MM_TO_PX,
                fill: "white",
                listening: false
            }
        }
    },
watch: {
    selectedShapeName(n){
        if(!n){
            this.toolbarOpts = null;
        }
    },

    cropperProps(n){
        if(n){
            this.cropperDialog = true;
        } else {
            this.cropperDialog = false;
        }
    },

    cropperDialog(n){
        if(!n){
            this.cropperProps = null;
        }
    },

    userMode(n){
      // toggle draggable except for background
      this.elements.forEach(e => {
        if(e.type !== 'background'){
          e.draggable = !n;
        }
      });

    }
},

    methods: {
      lockChanged(locked){
        // update locked property in elements
        const idx = this.elements.findIndex(e => e.name === this.selectedShapeName);
        if(idx >= 0){
          this.elements[idx].locked = locked;
        }
      },

      async fillChanged(color){
        console.log(color);
        const node = this.$refs.stage.getNode().findOne('.' + this.selectedShapeName);
        if(node){
          const type = this.elements.find(e => e.name === this.selectedShapeName).type;
          if(type == 'text'){
            node.fill(color);
            this.updateTransformer();
          } else if(type == 'qrcode'){
            // generate new qrcode and replace it
            const image = await this.generateQRCode(node.text, color);
            node.image(image);
            // update qrcodefill
            node.qrCodeFill = color;
          }
        }
      },

      sizeChanged(size){
        const node = this.$refs.stage.getNode().findOne('.' + this.selectedShapeName);
        if(node){
          node.fontSize(size * MM_TO_PX);
          this.updateTransformer();
        }
      },

      fontStyleChanged(style){
        console.log(style);
        const node = this.$refs.stage.getNode().findOne('.' + this.selectedShapeName);
        if(node){
          // if includes underline
          if(style.includes('underline')){
            node.textDecoration('underline');
            style = style.replace('underline', '');
          } else {
            node.textDecoration('');
          }
          node.fontStyle(style);
          this.updateTransformer();
        }
      },

      initCropper(id){
        const node = this.$refs.stage.getNode().findOne('.' + id);
        this.cropperProps = {
          src: node.image().src,
          aspectRatio: node.width() / node.height(),
          change: (evt) => {
            node.crop({
              x: evt.coordinates.left,
              y: evt.coordinates.top,
              width: evt.coordinates.width,
              height: evt.coordinates.height,
            });
          }
        }
      },

      deleteElement(){
        // delete selected element
        const idx = this.elements.findIndex(e => e.name === this.selectedShapeName);
        if(idx >= 0){
            this.elements.splice(idx, 1);
            this.selectedShapeName = '';
            this.updateTransformer();
        }
      },

      uploadFile(id, scope, types, postProcess = null) {
            // if id is null uuidv4
            if (!id) {
                id = uuidv4();
            }

            // set attributes
            this.$refs.fileInput.setAttribute('data-file-name', id);
            this.$refs.fileInput.setAttribute('data-file-scope', scope);
            this.$refs.fileInput.setAttribute('accept', types);
            if(postProcess){
                this.$refs.fileInput.setAttribute('data-post-process', postProcess);
            }
            this.$refs.fileInput.click();
        },

        async fileInputChange(e) {
            var file = e.target.files[0];
            // get from data attrs
            const fileName = e.target.getAttribute('data-file-name');
            const fileScope = e.target.getAttribute('data-file-scope');
            const postProcess = e.target.getAttribute('data-post-process');
            if (file) {
                if(file.size > 1024 * 1024 * 10){
                    this.$vs.notification({
                        icon: '<i class="bx bx-error"></i>',
                        color: 'danger',
                        position: 'top-right',
                        title: 'Ops!',
                        text: `La foto non può essere più grande di 10MB.`
                    })
                    return;
                }
                const loading = this.$vs.loading({
                    text: 'Caricamento in corso...'
                })
                try {
                    const result = await s3Client.uploadFile(file, fileName);
                    if(result.status == 200){
                        // get signed download url
                        const signedUrl = await s3Client.getSignedDownloadURL({name: fileName}); // signedUrl.url;
                        if(fileScope == 'builderImage'){

                          const image = new Image();
                          image.src = signedUrl.url;
                          image.id = fileName;
                          image.onload = () => {
                              let e = this.$refs.stage.getNode().findOne('.' + fileName);
                              if(e){
                                  const oldImage = e.image();

                                  e.image(image);

                                  // it must fill the same space
                                  // understand which is the biggest dimension and then crop it to fill the space

                                  const newH = image.naturalHeight;
                                  const newW = image.naturalWidth;
                                  const oldH = oldImage.naturalHeight;
                                  const oldW = oldImage.naturalWidth;

                                  // it must FILL not FIT the space
                                  const scale = Math.max(newH / oldH, newW / oldW);

                                  
                                  e.crop({
                                      x: 0,
                                      y: 0,
                                      width: oldW * scale,
                                      height: oldH * scale,
                                  });

                                  if(postProcess == 'cropper'){
                                    this.cropperProps = {
                                      src: signedUrl.url,
                                      aspectRatio: e.width() / e.height(),
                                      change: (evt) => {
                                        console.log(evt);
                                        e.crop({
                                          x: evt.coordinates.left,
                                          y: evt.coordinates.top,
                                          width: evt.coordinates.width,
                                          height: evt.coordinates.height,
                                        });

                                        /*// evt.image.transforms.rotate
                                        if(evt.image.transforms.rotate){
                                          e.crop({
                                            x: evt.coordinates.left,
                                            y: evt.coordinates.top,
                                            width: evt.coordinates.height,
                                            height: evt.coordinates.width,
                                          });
                                        }*/
                                        
                                      }
                                    }
                                  }


                              } else {
                                this.addImageCore(image, {
                                  id: fileName,
                                  name: fileName,
                                });
                              }
                          }
                        } else if(fileScope == 'builderBackgroundImage'){
                          const image = new Image();
                          image.src = signedUrl.url;
                          image.id = fileName;
                          image.onload = () => {
                              // it must be centered, cover all the layout keeping aspect ratio of image
                              const w = this.layout.width ;
                              const h = image.naturalHeight * (w / image.naturalWidth);

                              this.addImageCore(image, {
                                  x: 0,
                                  y: 0,
                                  width: w * MM_TO_PX,
                                  height: h * MM_TO_PX,
                                  draggable: false,
                                  listening: false,
                                  type: "background",
                              });
                          }
                        }
                    } else {
                        throw new Error('Error uploading file');
                    }
                } catch (error) {
                    console.error(error);
                    this.$vs.notification({
                        icon: '<i class="bx bx-error"></i>',
                        color: 'danger',
                        position: 'top-right',
                        title: 'Ops!',
                        text: `Impossibile caricare la foto.`
                    })
                }

                loading.close();
            }
      },
      getSerializedData(){
        
        return JSON.stringify(this.elements.map(e => {
            // get node position in mm from konva, bacuse the elements are not updated
            // with the new position

            const konvaNode = this.$refs.stage.getNode().findOne('.' + e.name);
            const pos = konvaNode.getAbsolutePosition();
            e.x = pos.x/this.scaleFactor;
            e.y = pos.y/this.scaleFactor;

            // handle image sources
            if(e.type === 'qrcode'){
                const image = konvaNode.image();
                return {
                    ...e,
                    image: image ? image.src : undefined,
                    qrCodeFill: konvaNode.qrCodeFill,
                }
            } else if(e.type === 'image' || e.type === 'background'){
              // we just store s3 image name
              return {
                  ...e,
                  image: e.image ? e.image.id : undefined,
                  crop: konvaNode.crop(),
              }
            } else if(e.type == 'text'){
              // add style, size, font, decoration
              return {
                  ...e,
                  text: konvaNode.text(),
                  fontSize: konvaNode.fontSize(),
                  fontFamily: konvaNode.fontFamily(),
                  fill: konvaNode.fill(),
                  align: konvaNode.align(),
                  textDecoration: konvaNode.textDecoration(),
                  fontStyle: konvaNode.fontStyle(),
              }
            } else {
                return e;
            }
        }));
      },

      getStageDistanceFromContainer(){
        // get konvajs bounding rect and container bounding rect
        const konva = document.querySelector('.konvajs-content').getBoundingClientRect();
        const container = this.$refs.stageContainer.$el.getBoundingClientRect();

        return {
            x: konva.x - container.x,
            y: konva.y - container.y,
        }
      },

      async savePreviewPicture(pictureId = null){
        // remove focus from transformer
        this.$refs.transformer.getNode().nodes([]);

        

        // render konva image to blob
        const stage = this.$refs.stage.getNode();
        const image = await stage.toImage({
            pixelRatio: 1,
            mimeType: 'image/jpeg',
            quality: 1,

            x: this.layout.marginLeft * MM_TO_PX, // crop margins
            y: this.layout.marginTop * MM_TO_PX,

            width: (stage.width() * this.scaleFactor - this.layout.marginRight * MM_TO_PX - this.layout.marginLeft * MM_TO_PX), 
            height: stage.height() * this.scaleFactor - this.layout.marginBottom * MM_TO_PX - this.layout.marginTop * MM_TO_PX,

        });


       

        // get blob
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0);

        const blob = await new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                resolve(blob);
            }, 'image/jpeg');
        });

        // destroy canvas
        canvas.remove();

        if(!pictureId){
          pictureId = this.builderData.layout.previewPictureId;
        }

        // upload blob to s3 with filename from previewPictureId if not null
        if(pictureId){
            const result = await s3Client.uploadBlob(blob, pictureId);
            if(result.status != 200){
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Impossibile salvare l'anteprima.`
                })
            }
        } else {
            this.$vs.notification({
                icon: '<i class="bx bx-error"></i>',
                color: 'danger',
                position: 'top-right',
                title: 'Ops!',
                text: `Impossibile salvare l'anteprima.`
            })
        }
        
      },

      async exportHQPng(){
        // remove focus from transformer
        this.$refs.transformer.getNode().nodes([]);

        // save scales
        const scaleX = this.$refs.stage.getNode().scaleX();
        const scaleY = this.$refs.stage.getNode().scaleY();

        // reset scales
        this.$refs.stage.getNode().scaleX(1);
        this.$refs.stage.getNode().scaleY(1);

        // render konva image to blob
        const stage = this.$refs.stage.getNode();
        const image = await stage.toImage({
            pixelRatio: 2,
            mimeType: 'image/png',
            quality: 1,
        });


        // set scales back
        this.$refs.stage.getNode().scaleX(scaleX);
        this.$refs.stage.getNode().scaleY(scaleY);

        // get blob
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0);

        const blob = await new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                resolve(blob);
            }, 'image/png');
        });

        // destroy canvas
        canvas.remove();

        // trigger download
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'render.png';
        a.click();

        // revoke url
        URL.revokeObjectURL(url);

        
      },

      async editLayout() {
            const loading = this.$vs.loading();
            var result = await apiCall('PATCH', '/Product/Layout', {
                ...this.builderData.layout,
                jsonData: this.getSerializedData()
            });
            await this.savePreviewPicture();
            loading.close();
            if (result.status == 200) {
                this.$vs.notification({
                    icon: '<i class="bx bx-check"></i>',
                    color: 'success',
                    position: 'top-right',
                    title: 'Fatto!',
                    text: `Tutte le modifiche sono state salvate.`
                })
            } else {
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Impossibile salvare le modifiche.`
                })
            }
        },

        async loadSave(){
          if(this.builderData.layout.type == 'Spine'){
            this.layout.height = this.builderData.product.height;
            this.layout.width = this.builderData.productVariant.dorsino;
            this.layout.marginTop = 0;
            this.layout.marginRight = 0;
            this.layout.marginBottom = 0;
            this.layout.marginLeft = 0;
          } else {
            this.layout.width = this.builderData.product.width;
            this.layout.height = this.builderData.product.height;
            this.layout.marginTop = this.builderData.product.marginTop;
            this.layout.marginRight = this.builderData.product.marginRight;
            this.layout.marginBottom = this.builderData.product.marginBottom;
            this.layout.marginLeft = this.builderData.product.marginLeft;
          }

          let data = JSON.parse(this.builderData.layout.jsonData);
          if(!data) return;

          // edit draggable
          data = data.map(e => {
            if(e.type !== 'background'){
              e.draggable = !this.userMode;
            }
            return e;
          });

          this.elements = await Promise.all(data.map(async e => {
            
              if(e.type === 'qrcode'){
                  const image = new Image();
                  image.src = e.image;
                  await new Promise((resolve, reject) => {
                      image.onload = () => {
                          resolve();
                      }

                      image.onerror = (err) => {
                          reject(err);
                      }
                  });
                  return {
                      ...e,
                      image,
                  }
              } else if(e.type === 'image' || e.type === 'background'){
                  // url from s3
                  const downloadUrl = await s3Client.getSignedDownloadURL({name: e.image});
                  const image = new Image();
                  image.src = downloadUrl.url;
                  image.id = e.image;
                  await new Promise((resolve, reject) => {
                      image.onload = () => {
                          resolve();
                      }

                      image.onerror = (err) => {
                          reject(err);
                      }
                  });

                  return {
                      ...e,
                      image,
                  }
              }
              return e;
          }));

        },

        async init() {
          this.userMode = this.builderData.userMode || false;

            this.loadSave().then(() => {
              this.$emit('ready');
            });

           // get screen page available space
            let availWidth = window.screen.availWidth;
            let availHeight = window.screen.availHeight - (this.builderData.heightSubtract || 200); // 200 px are lost in admin

            if(this.builderData.ssr){
              //headless mode
              availWidth = 1920;
              availHeight = 1080;
            }
            
            this.configKonva.width = this.layout.width * MM_TO_PX;
            this.configKonva.height = this.layout.height * MM_TO_PX;
            this.scaleFactor = Math.min(
                availWidth / this.configKonva.width,
                availHeight / this.configKonva.height
            );



            this.configKonva.scaleX = this.scaleFactor;
            this.configKonva.scaleY = this.scaleFactor;

            this.$nextTick(() => {
                // if user mode, resize stage
              let el = document.querySelector('.konvajs-content');
              console.log('el',el);
              if(el){
                  el.style.width = this.layout.width * MM_TO_PX * this.scaleFactor + 'px';
                  el.style.height = this.layout.height * MM_TO_PX * this.scaleFactor + 'px';
                  el.style.overflow = 'hidden';
              }
              
            });
        },

        handleTransform(e) {
       

            const element = e.target;
            if(element.attrs.type === 'text'){
                element.setAttrs({
                    width: Math.max(5, element.width() * element.scaleX()),
                    height: Math.max(5, element.height() * element.scaleY()),
                    scaleX: 1,
                    scaleY: 1,
                });
            }

            const boundingRect = this.getStageDistanceFromContainer();

            // move toolbar
            this.toolbarOpts = {
                ...this.toolbarOpts,
                // also sum how far is stage from left of parent stageContainer
                x: element.x() * this.scaleFactor + boundingRect.x,
                y: element.y() * this.scaleFactor + boundingRect.y,
            };

        },

        handleDrag(e) {
            if(this.userMode) return;

            const element = e.target;

            // prevent exiting from canvas margins
            const MIN_X = this.layout.marginLeft * MM_TO_PX;
            const MIN_Y = this.layout.marginTop * MM_TO_PX;
            const MAX_X = (this.layout.width - this.layout.marginRight) * MM_TO_PX; 
            const MAX_Y = (this.layout.height - this.layout.marginBottom) * MM_TO_PX;

            if(element.x() < MIN_X){
                element.x(MIN_X);
            }

            if(element.y() < MIN_Y){
                element.y(MIN_Y);
            }

            if(element.x() + element.width() > MAX_X){
                element.x(MAX_X - element.width());
            }

            if(element.y() + element.height() > MAX_Y){
                element.y(MAX_Y - element.height());
            }

            const boundingRect = this.getStageDistanceFromContainer();

            // move toolbar
            this.toolbarOpts = {
                ...this.toolbarOpts,
                x: element.x() * this.scaleFactor + boundingRect.x,
                y: element.y() * this.scaleFactor + boundingRect.y,
            };

        },



        addImageCore(image, opts = {}) {
            // get w and h from naturalWidth and naturalHeight, but it must fits in the layout
            let w = 100;
            // calc h to keep the aspect ratio
            const h = (w * image.naturalHeight) / image.naturalWidth;

            const u = uuidv4();
            let o = {
                type: "image",

                id: u,
                name: u,
                x: (this.layout.width - w) * MM_TO_PX / 2,
                y: (this.layout.height - h) * MM_TO_PX / 2,
                image,
                draggable: this.userMode ? false : true,

                width: w * MM_TO_PX,
                height: h * MM_TO_PX,

                zIndex: 0,

                locked: false,
            };

            o = Object.assign(o, opts); // override default options
            
            this.elements.push(o);

        },

        
        async generateQRCode(text = 'wised.it', color = '#000000FF') {            
            return await new Promise((resolve, reject) => {
                QRCode.toDataURL(text, {
                    rendererOpts: {
                        quality: 1
                    },
                    margin: 1,
                    type: 'image/png',

                    color: {
                    dark: color,
                    light:"#0000"
                    }
                },(err, url) => {
                    if (err) {
                        console.error(err)
                        reject(err);
                    }
                    const image = new Image();
                    image.src = url;
                    image.onload = () => {
                        resolve(image);
                    }
                })
            });
        },

        async addQR(text = 'wised.it', color = '#000000FF') {    
          const image = await this.generateQRCode(text, color);        
            
          this.addImageCore(image, {
              type: "qrcode",
              width: 50 * MM_TO_PX,
              height: 50 * MM_TO_PX,
              text,
              qrCodeFill: color,
          });
        },

        addParagraph(){
            if (!this.paragraphOptions) {
                this.$set(this, 'paragraphOptions', []);
            }

            this.elements.push({
                type: "text",

                id: "paragraph_" + (this.elements.length + 1),
                name: "paragraph_" + (this.elements.length + 1),
                x: 1 * MM_TO_PX,
                y: 1 * MM_TO_PX,
                text: "Nuovo paragrafo",
                fontSize: 20 * MM_TO_PX,
                fontFamily: "Arial",
                fill: "black",
                draggable: this.userMode ? false : true,

                width: Math.min(...[100, this.layout.width]) * MM_TO_PX,
                height: 100 * MM_TO_PX,

                editingMode: false,

                locked: false,
            });

            // add konva transformer


        },


      doubleClick(e) {
        const textNode = e.target;
        const tr = this.$refs.transformer.getNode();
        const stage = tr.getStage();
        // hide text node and transformer:
        textNode.hide();
        tr.hide();

        // create textarea over canvas with absolute position
        // first we need to find position for textarea
        // how to find it?

        // at first lets find position of text node relative to the stage:
        var textPosition = textNode.absolutePosition();
        var textSize = {
          width: textNode.width() * this.scaleFactor,
          height: textNode.height() * this.scaleFactor,
        };

        // so position of textarea will be the sum of positions above:
        var areaPosition = {
          x: stage.container().offsetLeft + (textPosition.x),
          y: stage.container().offsetTop + (textPosition.y )
        };

        // create textarea and style it
        var textarea = document.createElement('textarea');
        this.$refs.stageContainer.$el.appendChild(textarea);
        var rotation = textNode.rotation();

        // apply many styles to match text on canvas as close as possible
        // remember that text rendering on canvas and on the textarea can be different
        // and sometimes it is hard to make it 100% the same. But we will try...
        textarea.value = textNode.text();
        textarea.style.position = 'absolute';
        textarea.style.top = (areaPosition.y ) + 'px';
        textarea.style.left = (areaPosition.x ) + 'px';
        textarea.style.width = textSize.width + 'px';
        textarea.style.height = textSize.height + 'px';
        textarea.style.fontSize = textNode.fontSize() * this.scaleFactor + 'px';
        textarea.style.border = '2px solid #ddd';
        textarea.style.padding = '0px';
        textarea.style.margin = '0px';
        textarea.style.overflow = 'hidden';
        textarea.style.background = 'none';
        textarea.style.outline = 'none';
        textarea.style.resize = 'none';
        textarea.style.lineHeight = textNode.lineHeight();
        textarea.style.fontFamily = textNode.fontFamily();
        textarea.style.transformOrigin = 'left top';
        textarea.style.textAlign = textNode.align();
        textarea.style.color = textNode.fill();

        // set decoration and style
        if(textNode.textDecoration()){
          textarea.style.textDecoration = textNode.textDecoration();
        }

        if(textNode.fontStyle()){
          textarea.style.fontStyle = textNode.fontStyle();
        }

        // unset minWidth, maxWidth, minHeight, maxHeight
        textarea.style.minWidth = '0';
        textarea.style.maxWidth = 'none';
        textarea.style.minHeight = textSize.height + 'px';
        textarea.style.maxHeight = 'none';
        var transform = '';
        if (rotation) {
          transform += 'rotateZ(' + rotation + 'deg)';
        }

        var px = 0;
        // also we need to slightly move textarea on firefox
        // because it jumps a bit
        var isFirefox =
          navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        if (isFirefox) {
          px += 2 + Math.round(textNode.fontSize() / 20);
        }
        transform += 'translateY(-' + px + 'px)';

        textarea.style.transform = transform;

        // reset height
        textarea.style.height = 'auto';
        // after browsers resized it we can set actual value
        textarea.style.height = textarea.scrollHeight + 3 + 'px';

        textarea.focus();

        function removeTextarea() {
          textarea.parentNode.removeChild(textarea);
          window.removeEventListener('click', handleOutsideClick);
          textNode.show();
          tr.show();
          tr.forceUpdate();
        }

        function setTextareaWidth(newWidth) {
          if (!newWidth) {
            // set width for placeholder
            newWidth = textNode.placeholder.length * textNode.fontSize();
          }
          // some extra fixes on different browsers
          var isSafari = /^((?!chrome|android).)*safari/i.test(
            navigator.userAgent
          );
          var isFirefox =
            navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
          if (isSafari || isFirefox) {
            newWidth = Math.ceil(newWidth);
          }

          var isEdge =
            document.documentMode || /Edge/.test(navigator.userAgent);
          if (isEdge) {
            newWidth += 1;
          }
          textarea.style.width = newWidth + 'px';
        }

        textarea.addEventListener('keydown', function (e) {
          // hide on enter
          // but don't hide on shift + enter
          if (e.keyCode === 13 && !e.shiftKey) {
            textNode.text(textarea.value);
            removeTextarea();
          }
          // on esc do not set value back to node
          if (e.keyCode === 27) {
            removeTextarea();
          }
        });

        textarea.addEventListener('keydown', function (e) {
          scale = textNode.getAbsoluteScale().x;
          setTextareaWidth(textNode.width() * scale);
          textarea.style.height = 'auto';
          textarea.style.height =
            textarea.scrollHeight + textNode.fontSize() + 'px';
        });

        function handleOutsideClick(e) {
          if (e.target !== textarea) {
            textNode.text(textarea.value);
            removeTextarea();
          }
        }
        setTimeout(() => {
          window.addEventListener('click', handleOutsideClick);
        });
      },

handleTransformEnd(e) {
      if(this.userMode) return;
      // shape is transformed, let us save new attrs back to the node
      // find element in our state
      const rect = this.elements.find(
        (r) => r.name === e.target.name()
      );
      // update the state
      rect.x = e.target.x();
      rect.y = e.target.y();
      rect.rotation = e.target.rotation();

      // as scale stretches the width and height, we need to recalculate them
        rect.width = e.target.width()
        rect.height = e.target.height()


    },
    handleStageMouseDown(e) {
      
      // clicked on stage - clear selection
      if (e.target === e.target.getStage()) {
        this.selectedShapeName = '';
        this.updateTransformer();
        return;
      }

      // clicked on transformer - do nothing
      const clickedOnTransformer =
        e.target.getParent().className === 'Transformer';
      if (clickedOnTransformer) {
        return;
      }

      // find clicked rect by its name
      const name = e.target.name();
      const rect = this.elements.find((r) => r.name === name);
      if (rect) {
        const node = e.target;
        const element = e.target;
        this.toolbarOpts = null;
        this.selectedShapeName = name;

        this.$nextTick(() => {
          const boundingRect = this.getStageDistanceFromContainer();
          this.toolbarOpts = {
              ...this.toolbarOpts || {},
            x: element.x() * this.scaleFactor + boundingRect.x,
            y: element.y() * this.scaleFactor + boundingRect.y,
            type: rect.type,
            id: rect.id,
            locked: rect.locked,

            // add options for paragraph
            ... (rect.type === 'text' ? {
              text: node.text(),
              fontSize: node.fontSize() / MM_TO_PX,
              fontFamily: node.fontFamily(),
              fill: node.fill(),
              align: node.align(),
              textDecoration: node.textDecoration(),
              fontStyle: node.fontStyle(),
            } : {}),

            ... (rect.type === 'qrcode' ? {
              text: rect.text,
              fill: rect.qrCodeFill,
            } : {}),
            
          };

        });
      } else {
        this.selectedShapeName = '';
      }
      this.updateTransformer();
    },
    updateTransformer() {
      if (this.userMode){
        // disable stretching
        this.$refs.transformer.getNode().enabledAnchors([]);
        // disable rotation using rotateEnabled
        this.$refs.transformer.getNode().rotateEnabled(false);
      } else {
        // enable only preserving aspect ratio, disable others
        this.$refs.transformer.getNode().enabledAnchors(['top-left', 'top-right', 'bottom-left', 'bottom-right']);
        // enable rotation
        this.$refs.transformer.getNode().rotateEnabled(true);
      }
      // here we need to manually attach or detach Transformer node
      const transformerNode = this.$refs.transformer.getNode();
      const stage = transformerNode.getStage();
      const { selectedShapeName } = this;

      const selectedNode = stage.findOne('.' + selectedShapeName);
      // do nothing if selected node is already attached
      if (selectedNode === transformerNode.node()) {
        return;
      }

      if (selectedNode) {
        // attach to another node
        transformerNode.nodes([selectedNode]);
      } else {
        // remove transformer
        transformerNode.nodes([]);
      }
    },
    },
    mounted() {
        this.init();

    }
}
</script>
<style>
.cropper {
  max-height: 80vh!important;
}
</style>