<template>
    <vs-row align="center" class="builder-toolbar" :style="{left: localOptions.x + 'px', top: localOptions.y - 60 + 'px'}">
        <!-- color picker-->
        <vs-input style="width: 80px; z-index: 1000;" type="color" v-model="localOptions.fill" v-if="['text', 'qrcode'].includes(localOptions.type)" @change="(e) => $emit('fillChanged', e.target.value)"/>

         <!-- size-->
            <vs-input style="width: 80px; z-index: 1000;" type="number" v-model="localOptions.fontSize" min="6" max="48" v-if="['text'].includes(localOptions.type)" @change="(e) => $emit('sizeChanged', e.target.value)"/>

            <!-- font family-->
             <!-- TODO
            <vs-select style="width: 150px; z-index: 1000;" v-model="localOptions.font" v-if="['text'].includes(localOptions.type) && fonts.length">
                <vs-select-item v-for="font in fonts" :key="font" :value="font" :text="font" />
            </vs-select>
            -->

         <!-- BOLD, UNDERLINE, ITALIC IF TEXT-->
            <vs-button @click="buildFontStyle('bold')" :transparent="!localOptions.bold" style="height: 42px; width: 42px;" v-if="['text'].includes(localOptions.type)">
                <i class="bx bx-bold bx-sm"></i>
            </vs-button>

            <vs-button @click="buildFontStyle('underline')" :transparent="!localOptions.underline"  style="height: 42px; width: 42px;" v-if="['text'].includes(localOptions.type)">
                <i class="bx bx-underline bx-sm"></i>
            </vs-button>

            <vs-button @click="buildFontStyle('italic')" :transparent="!localOptions.italic"  style="height: 42px; width: 42px;" v-if="['text'].includes(localOptions.type)">
                <i class="bx bx-italic bx-sm"></i>
            </vs-button>

            <!-- upload-->
            <vs-button color="primary" style="height: 42px; width: 42px;" v-if="['image'].includes(localOptions.type)" @click="$emit('upload')">
                <i class="bx bx-upload bx-sm"></i>
            </vs-button>

            <!-- crop -->
            <vs-button color="primary" style="height: 42px; width: 42px;" v-if="['image'].includes(localOptions.type)" @click="$emit('crop')">
                <i class="bx bx-crop bx-sm"></i>
            </vs-button>

            <!-- lock -->
            <vs-button color="primary" style="height: 42px; width: 42px;" @click="toggleLock" v-if="localOptions.locked && !userMode">
                <i class="bx bx-lock-alt bx-sm"></i>
            </vs-button>

            <!-- unlock -->
            <vs-button color="primary" style="height: 42px; width: 42px;" @click="toggleLock" v-if="!localOptions.locked && !userMode">
                <i class="bx bx-lock-open-alt bx-sm"></i>
            </vs-button>
            
            <!-- trash on right -->
            <vs-button color="danger" style="height: 42px; width: 42px;" v-if="!userMode" @click="$emit('delete')">
                <i class="bx bx-trash bx-sm"></i>
            </vs-button>



    </vs-row>
</template>

<script>
import { apiCall } from '@/client.js';
export default {
    name: 'BuilderToolbar',
    props: {
        options: {
            type: Object,
            default: () => {}
        },

        userMode: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        toggleLock(){
            this.localOptions.locked = !this.localOptions.locked;
            this.localOptions.__ob__.dep.notify();
            this.$emit('lockChanged', this.localOptions.locked);
        },

        async getFonts() {
            var result = await apiCall('GET', '/Font');
            if (result.status == 200) {
                this.fonts = result.data;
            }
        },

        buildFontStyle(toggle = null){
            if(toggle != null){
                this.localOptions[toggle] = !this.localOptions[toggle];
            }
            // build fontStyle: it's a string with all the options separed by space
            let fontStyle = '';
            if (this.localOptions.bold) {
                fontStyle += 'bold ';
            }
            if (this.localOptions.italic) {
                fontStyle += 'italic ';
            }
            if (this.localOptions.underline) {
                fontStyle += 'underline ';
            }
            if(this.localOptions.type == 'text'){
                this.$emit('fontStyleChanged', fontStyle);
            }

            this.localOptions.__ob__.dep.notify();
        }
    },

    mounted() {
        this.getFonts();

        Object.assign(this.localOptions, this.options);
        console.log(this.localOptions);
        // parse fontstyle
        if(this.localOptions.fontStyle){
            let fontStyle = this.localOptions.fontStyle.split(' ').concat(this.localOptions.textDecoration.split(' '));
            this.localOptions.bold = fontStyle.includes('bold');
            this.localOptions.italic = fontStyle.includes('italic');
            this.localOptions.underline = fontStyle.includes('underline');
        }

        this.localOptions.__ob__.dep.notify();

        console.log(this.localOptions);
    },

    data(){
        return {
            fonts:[],

            localOptions: {},
        }
    },

    watch: {
        options: {
            handler: function (val) {
                // only set x and y
                this.localOptions.x = val.x;
                this.localOptions.y = val.y;

                // set type
                this.localOptions.type = val.type;

                this.localOptions.__ob__.dep.notify();
            },
            deep: true
        },
    }
}
</script>

<style scoped>
.builder-toolbar {
    position: absolute;
    width: auto;
    gap: 5px!important;
    z-index: 10000;
}
</style>